<template>
  <div class="contenier-block-method">
    <div class="pay-method" :class="[methodPay.id === activeMethod ? 'selected' : '']">
      <img
          :src="getPictureUrl(methodPay)"
          :data-src="getPictureUrl(methodPay)"
          :alt="'MagnetBet казино депозит '+ methodPay.name"
          class="pay-method-image"
      >
      <span class="w-100">
        {{ methodPay.name }} <br>
        <small v-if="methodPay.min_amount">(мин․ {{ methodPay.min_amount }} руб)</small>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentMethod',
  props: {
    methodPay: Object,
    activeMethod: Number
  },
  methods: {
    getPictureUrl(url) {
      if (url.card_picture) {
        return url.card_picture;
      } else {
        return this.$_config.baseUrl + (url.picture ?? url.img)
      }
    },
  },

}
</script>

<style scoped>
.pay-method {
  align-items: center;
  background: #1f2229;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #83888b;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 45px;
  margin-bottom: 10px;
  padding: 0 10px;
  white-space: nowrap;
  width: 100%;
  line-height: 1;
  justify-content: start;
}

.pay-method:hover {
  border: 2px solid #535d71;
  color: #fff;
}

.pay-method-image {
  height: 22px;
  width: 22px;
  object-fit: cover;
}

.pay-method span {
  margin-left: 20px;
  text-align: left;
}

.selected {
  border: 2px solid #535d71;
  color: #fff;
}
</style>
  