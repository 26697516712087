<template>
  <div class="py-3 py-lg-5 col-12"></div>
</template>
<script>

export default {
  methods: {
   getLobbyBalance(termid64) {
      this.axios.post(
          this.$_config.baseUrl + '/Api/getLobbyBalance',
          {
            termid64: termid64
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(() => {
        parent.postMessage('close-lobby-iframe', "*");
      }).catch(err => {
        console.log(err.message)
        parent.postMessage('close-lobby-iframe', "*");
      })
      return true
    },
  },
  mounted() {
    const termid64 = this.$route.query.termid64
    this.getLobbyBalance(termid64);
  }
}
</script>
