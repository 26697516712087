<template>
  <div v-if="userData" class="px-4 mt-5 ">
    <div class="row px-0" v-if="histories">
      <div class="col-12 d-flex history cursor-pointer py-2">
        <div class="pay mw-sm-25">
          <span class="top-text"></span>
        </div>
        <div class="pay mw-sm-25">
          <span class="top-text">Сумма</span>
        </div>
        <div class="pay mw-sm-25">
          <span class="top-text" v-if="histories[0].picture">Метод</span>
        </div>
        <div class="pay mw-sm-25 d-none d-sm-block">
          <span class="top-text">Дата создания</span>
        </div>
        <div class="pay mw-sm-25">
          <span class="top-text">Статус</span>
        </div>
      </div>

      <div v-for="(history, index) in histories" :key="index" class="col-12 history cursor-pointer py-2">
        <div class="winner d-flex align-items-center justify-content-between" @click="showDateHistory(index)">
          <div class="pay mw-sm-25">
            #{{ history.id }}
          </div>
          <div class="pay mw-sm-25">
            {{ history.amount }}
          </div>
          <div class="pay mw-sm-25 method-img">
            <img :src="cardMethod.card_picture" v-if="history.method_type == 'card'">
            <img :src="sbpMethod.card_picture" v-else-if="history.method_type == 'sbp'">
            <div v-else>
              <img :src="getPictureUrl(history.picture)" v-if="history.picture">
            </div>
          </div>
          <div class="pay mw-sm-25 d-none d-sm-block">
            {{ history.date }}
          </div>
          <div class="pay mw-sm-25 pay-status">
            <span class="confirmed" v-if="!history.status">Успешно</span>
            <span class="panding" v-else-if="history.status_type == 1">В ожидании</span>
            <span class="support_confirmed" v-else-if="history.status_type == 2">Суппорт подтвердил</span>
            <span class="confirmed" v-else-if="history.status_type == 3">Подтверждено</span>
            <span class="canceled" v-else-if="history.status_type == 4">Отменено</span>
            <span class="canceled_returned" v-else-if="history.status_type == 5">Возвращено</span>
          </div>
        </div>
        <div class="history-desc" :class="{ active: index === activeHistory }">
          <div class="d-flex">
            <div>
              <span class="d-block">Сумма транзакции: {{ history.amount }}</span>
              <span v-if="history.comment" class="d-block">Вывод на {{ outputTo(history.comment) }}</span>
              <span class="d-block d-sm-none"> Дата операции: {{ history.date }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-lg-4 mx-0 col-12 col-lg-10 col-xl-8 mx-auto px-0" v-else>
      <div class="text-center">
        <h5>История отсутствует </h5>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "History",
  props: {
    histories: Array || null
  },
  data() {
    return {
      activeHistory: null,
      cardMethod: {
        card_picture: require('@/../public/images/icons/karta.svg')
      },
      sbpMethod: {
        card_picture: require('@/../public/images/icons/sbp.svg')
      },
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    }
  },
  methods: {
    showDateHistory(value) {
      if (this.activeHistory != value) {
        this.activeHistory = value;
      } else {
        this.activeHistory = null;
      }
    },
    outputTo(val) {
      const name = val.split(' ');
      return name[3];
    },
    getPictureUrl(url) {
      return this.$_config.baseUrl + url
    },
  },
  mounted() {
    if (!this.userData) {
      return this.$router.push({name: 'login'})
    }
  }
}

</script>

<style scoped>
.history {
  min-height: 40px;
  border-bottom: 1px solid #585858;;
  font-size: 12px;
}

.top-text {
  color: #aab0bd;
}
.pay {
  min-width: 20%;
  height: 100%;
  text-align: start;
}

.method-img img {
  width: 25px;
  height: 25px;
}

.pay .confirmed {
  color: #3d9b2a !important;
}

.pay .panding {
  color: #2d61f0 !important;
}

.pay .support_confirmed {
  font-size: 12px;
  color: #307c0d !important;
}

.pay .canceled_returned {
  font-size: 12px;
  color: #daa208 !important;
}

.pay .canceled {
  color: #f02d2d !important;
}

.history-desc {
  display: none;
  text-align: start;
}
.history-desc span {
  color: #a6a9ac;
}

.active {
  display: block;
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 992px) {
  .pay button {
    width: 100%;
    font-size: 10px !important;
  }
  .history,
  .pay {
    font-size: 10px;
  }
  .pay {
    max-width: 50%;
  }
}

@media (max-width: 575px) {
  .pay {
    min-width: 25%;
  }
  .pay button {
    height: 25px;
  }
  .method-img img {
    width: 20px;
    height: 20px;
  }
}

</style>