<template>
  <div v-if="userData" class="profile-content col-12 row mx-0 justify-content-between px-0">
    <div class="col-12 col-sm-5 col-md-6 col-lg-5 col-xl-4">
      <div class="col-12 col-xl-11 mx-auto profile-description py-4 px-4">
        <h6 class="w-100 text-muted text-center">
          Добро пожаловать {{ userData.login }}
        </h6>

        <div class="col-12 px-0" v-if="userData.level == 1">
          <img v-if="!userData.selfie_verified" :src="profileIcons[0]" alt="MagnetBet user profile icon"
            class="profile-user-icon mw-100">
          <img v-else :src="profileIcons[3]" alt="MagnetBet user profile icon" class="profile-user-icon mw-100">
        </div>
        <div class="col-12 px-0" v-else-if="userData.level == 2">
          <img :src="profileIcons[1]" alt="MagnetBet user profile icon" class="profile-user-icon mw-100">
        </div>
        <div class="col-12 px-0" v-else-if="userData.level == 3">
          <img :src="profileIcons[2]" alt="MagnetBet user profile icon" class="profile-user-icon mw-100">
        </div>
        <div class="col-12 px-0">
          <p class="w-100">
            Статус : {{ userData.levelTitle }}
          </p>
        </div>
        <div class="col-12 px-0">
          <p class="w-100">
            Баланс: <br> {{ userData.balance }} Руб
          </p>
        </div>
        <div class="col-12 px-0">
            <button class="btn red-btn w-100" name="deposit" @click="showPayment">
              Пополнить баланс
            </button>

        </div>
      </div>
      <div class="col-12 col-lg-8 mx-auto py-2 px-0 text-left">
        <a class="text-muted" href="#" @click.prevent="logout">
          <small>
            Выход |
            <i v-if="!loggingOut" class="fa fa-arrow-right"></i>
            <i v-else class="fa fa-spinner fa-spin small-spin text-white"></i>
          </small>

        </a>
      </div>
    </div>
    <div class="col-12 col-sm-7 col-md-6 col-lg-7 col-xl-7">
      <div v-if="userData" class="user-info bg-transparent text-white">
        <b-list-group>
          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Логин</small>
              </div>
              <div class="col-12 text-left">
                <span>{{ userData.login }}</span>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Ваше имя</small>
              </div>
              <div class="col-12 text-left">
                <span v-if="userData.name">{{ userData.name }}</span>
                <form class="w-100" v-else>
                  <Alert :message="updateNameResponse"></Alert>
                  <fieldset class="form-group row m-0">
                    <div class="col-6 col-md-5 col-lg-8 px-0">
                      <input type="text" placeholder="Ваше имя *" required aria-required="true"
                        class="form-control main-input" v-model="usersName">
                    </div>
                    <div class="col-6 col-lg-4 px-0 text-lg-center">
                      <b-button v-if="userData.name == ''" class="btn float-right gray-btn"
                        @click.stop.prevent="updateUsersName">
                        Изменить
                      </b-button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left">
                <span class="user-phone-text">
                  <input v-mask="'+#(###)-###-##-##'" type="text" class="user-phone main-input text-left border-0 pt-2"
                    disabled :value="userData.phone">
                </span>

                <small class="float-right text-muted"
                  v-if="!userData.phone_confirmed && userData.sum_replenishment < 2500 && !userData.isPromo">
                  Для активации бонуса необходимо сделать 2500 руб депозит.
                </small>
                <b-button
                  v-else-if="!userData.phone_confirmed && (userData.sum_replenishment >= 2500 || userData.isPromo)"
                  class="gray-btn float-right" name="confirmPhone" lass="gray-btn float-right"
                  @click.prevent="confirmPhone">
                  Подтвердить
                </b-button>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Пароль</small>
              </div>
              <div class="col-12 text-left">
                <span class="pt-2 d-inline-block">**********</span>
                <b-button name="changePassword" v-if="userData.phone_confirmed || userData.email_confirmed"
                  class="gray-btn float-right" @click.prevent="changePassword">
                  Изменить
                </b-button>
                <small v-else class="col-9 px-0 d-inline-block float-right text-muted">
                  Пароль можно изменить только после подтверждения номера телефона или адреса электронной почты
                </small>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left">
                <span class="d-inline-block pt-2 user-email-text ">{{ userData.email }}</span>

                <small class="float-right text-muted"
                  v-if="!userData.email_confirmed && userData.sum_replenishment < 2500 && !userData.isPromo">
                  Для активации бонуса необходимо сделать 2500 руб депозит.
                </small>

                <b-button
                  v-else-if="userData.email_confirmed == 0 && (userData.sum_replenishment >= 2500 || userData.isPromo)"
                  name="confirmEmail" class="gray-btn float-right" @click.prevent="confirmEmail">
                  Подтвердить
                </b-button>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Верификация</small>
              </div>
              <div class="col-12 text-left">

                <span class="d-inline-block pt-2 user-verified-text" v-if="accountIdentifyStatus"
                  v-html="accountIdentifyStatus"></span>
                <small v-if="accountIdentifyStatusDesc" v-html="accountIdentifyStatusDesc"></small>

                <b-button name="confirmEmail" v-if="showIdentificationButton" class="gray-btn float-right"
                  @click.prevent="confirmPassport">
                  Пройти верификацию
                </b-button>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent" v-if="userData.sum_replenishment >= 2500 || userData.isPromo">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Верификация Селфи</small>
              </div>
              <div class="col-12 text-left">
                <span class="d-inline-block pt-2 user-verified-text" v-if="userSelfieStatus"
                  v-html="userSelfieStatus"></span>
                <small v-if="userSelfieStatusDesc" v-html="userSelfieStatusDesc"></small>

                <b-button name="confirmSelfie" class="gray-btn float-right" v-if="showSelfieButton"
                  @click.prevent="confirmSelfieModal">
                  Селфи подтверждения
                </b-button>
              </div>
            </div>
          </b-list-group-item>

        </b-list-group>

      </div>
    </div>

    <ConfirmPassport :user-data="userData" v-if="userData.verified !== 1"
      @changed="updateState()"></ConfirmPassport>
    <ConfirmSelfie :user-data="userData" @changed="updateStateForSelfie()"></ConfirmSelfie>
    <ConfirmEmail :user-data="userData"></ConfirmEmail>
    <ChangePassword :user-data="userData"></ChangePassword>
    <ConfirmPhoneViaCall :user-data="userData" v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'">
    </ConfirmPhoneViaCall>
    <ConfirmPhone :user-data="userData" v-else></ConfirmPhone>
  </div>
  <div v-else class="col-12 col-lg-5 d-none d-lg-block px-lg-0
                    row justify-content-center w-100 color-red mx-0 h-100 py-4 text-center mx-auto">
    <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
  </div>
</template>

<script>
import ConfirmPhone from '@/components/user/actions/ConfirmPhone'
import ConfirmEmail from '@/components/user/actions/ConfirmEmail'
import ChangePassword from '@/components/user/actions/change-password/ChangePassword'
import ConfirmPhoneViaCall from '@/components/user/actions/ConfirmPhoneViaCall'
import ConfirmPassport from '@/components/user/actions/ConfirmPassport'
import Alert from '@/components/Alert'
import ConfirmSelfie from './actions/ConfirmSelfie.vue'

export default {
  name: 'Profile',
  data() {
    return {
      profileIcons: [
        require('@/../public/images/icons/levels/1_big.png'),
        require('@/../public/images/icons/levels/2_big.png'),
        require('@/../public/images/icons/levels/3_big.png'),
        require('@/../public/images/icons/levels/self-1-big.png')
      ],
      loggingOut: false,

      identityData: null,
      selfieDataStatus: null,

      userSelfieStatus: null,
      accountIdentifyStatus: null,

      accountIdentifyStatusDesc: null,
      userSelfieStatusDesc: null,

      showIdentificationButton: false,
      showSelfieButton: false,

      updateNameResponse: null,
      usersName: ''
    }
  },
  components: {
    ConfirmPhone,
    ConfirmEmail,
    ChangePassword,
    ConfirmPhoneViaCall,
    ConfirmPassport,
    Alert,
    ConfirmSelfie
  },
  async created() {
    await this.$store.dispatch('fetchUserIdentityData')
  },
  computed: {
    setDates() {
      let date = new Date()
      let monthAgo = new Date(date)
      monthAgo.setDate(monthAgo.getDate() - 30)
      let dateOptions = { hour12: false, hour: "2-digit", minute: "2-digit", year: 'numeric', month: 'short', day: '2-digit' }
      date = new Date(date).toLocaleString('ru-RU', dateOptions)
      monthAgo = new Date(monthAgo).toLocaleString('ru-RU', dateOptions)

      return { now: date, monthAgo: monthAgo }
    },
    userData() {
      return this.$store.getters.userData
    },
    userIdentityData() {
      return this.$store.getters.identityData
    },
    mainSettings() {
      return this.$store.getters.getMainSettings
    },
    userDataFetched() {
      return this.$store.getters.userDataFetched
    }
  },
  updated() {
    this.identityData = this.$store.getters.identityData
    this.selfieDataStatus = this.$store.getters.userData.selfieData.status
  },
  mounted() {
    this.identityData = this.$store.getters.identityData
    this.selfieDataStatus = this.$store.getters.userData.selfieData.status
    setTimeout(() => {
      this.updateState()
      this.updateStateForSelfie()
    }, 1000)
  },
  methods: {
    logout() {
      this.loggingOut = true
      this.axios.get(
        this.$_config.baseUrl + '/Api/logout'
      ).then(() => {
        this.$store.dispatch('removeUserData')
        this.$router.push({ name: 'home' })
      }).catch(err => {
        console.log(err)
      })
    },
    showPayment(){
      this.$root.$emit('bv::show::modal', 'paymentModal')
    },
    changePassword() {
      this.$root.$emit('bv::show::modal', 'changePasswordModal')
    },
    confirmPhone() {
      this.$root.$emit('bv::show::modal', 'confirmPhoneModal')
    },
    confirmEmail() {
      this.$root.$emit('bv::show::modal', 'confirmEmailModal')
    },
    confirmPassport() {
      this.$root.$emit('bv::show::modal', 'confirmPassportModal')
    },
    confirmSelfieModal() {
      this.$root.$emit('bv::show::modal', 'confirmSelfieModal')
    },
    setUserSelfieStatus() {
      if (!this.userData) {
        return
      }
      this.selfieDataStatus = this.$store.getters.userData.selfieData.status
      switch (this.selfieDataStatus) {
        case -1:
          this.showSelfieButton = true
          this.userSelfieStatus = '<strong class="text-danger">Необходимо пройти верификацию Селфи !</strong>'
          break;
        case 0:
          this.showSelfieButton = false
          this.userSelfieStatus = '<span class="text-warning">Данные в оброботке</span>'
          break;
        case 1:
          this.showSelfieButton = false
          this.userSelfieStatus = '<span class="text-success">Селфи верифицирован</span>'
          break;
        case 2:
          this.showSelfieButton = true
          this.userSelfieStatus = '<span class="text-warning">Получено запрос на селфию верификацию</span>'
          this.userSelfieStatusDesc = `<span class="text-danger">Верификация селфи отказано - ${this.$store.getters.userData.selfieData.reject_reason}</span>`
          break;
        default:
          break;
      }
    },
    setAccountIdentifyStatus() {
      if (!this.userData) {
        return
      }
      let accountVerified = this.userData.verified
      let identifyConfirmedStatus = false

      if (this.identityData) {
        identifyConfirmedStatus = this.identityData.approved
      }
      this.showIdentificationButton = false
      this.accountIdentifyStatus = null

      switch (accountVerified) {
        case 0:
          this.showIdentificationButton = true
          this.accountIdentifyStatus = '<span class="text-danger">Аккаунт не верифицирован</span>'

          if (identifyConfirmedStatus === 2) {
            const reason = this.identityData.reject_reason
            this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
          }
          if (identifyConfirmedStatus === 0) {
            this.showIdentificationButton = false
            this.accountIdentifyStatus = '<span class="text-warning">Данные в оброботке</span>'
          }
          break;
        case -1:
          this.showIdentificationButton = true
          this.accountIdentifyStatus = '<strong class="text-danger">Необходимо пройти верификацию !</strong>'

          if (identifyConfirmedStatus === 0) {
            this.showIdentificationButton = false
            this.accountIdentifyStatus = '<span class="text-warning">Данные в оброботке</span>'
          }
          if (identifyConfirmedStatus === 2) {
            const reason = this.identityData.reject_reason
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<span class="text-warning">Получено запрос на верификацию</span>'
            this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
          }
          break
        case 1:
          this.accountIdentifyStatus = '<span class="text-success">Аккаунт верифицирован</span>'
          break
      }
    },
    updateState() {
      this.identityData = this.$store.getters.identityData
      this.setAccountIdentifyStatus()
    },
    updateStateForSelfie() {
      this.setUserSelfieStatus()
    },
    updateUsersName() {
      this.updateNameResponse = { status: 'loading' }
      let queryString = `name=${this.usersName}`
      this.axios.post(
        this.$_config.baseUrl + '/Api/updateUserName/', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.updateNameResponse = data

        if (data.status !== "error") {
          this.$store.dispatch('fetchUserData')
          setTimeout(() => {
            this.updateNameResponse = null
          }, 2000)
        }
      }).catch(() => {
        this.updateNameResponse = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
      })
    }
  },
  watch: {
    userDataFetched(newState) {
      if (newState) {
        if (!this.userData.login) {
          window.location.reload()
        }
        this.updateState()
        this.updateStateForSelfie()
      }
    },
    identityData: {
      handler: function (newValue) {
        if (newValue) {
          this.updateState()
        }
      },
      deep: true

    },
    userData: {
      handler: function (data) {
        if (data) {
          this.setAccountIdentifyStatus()
          this.setUserSelfieStatus()
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.profile-description {
  background-color: #3d3d3d;
}

.user-info .list-group-item {
  border: none;
  padding: 5px 0 !important;
  border-bottom: 1px solid #6a7075;
}

.wheel-access .progress {
  height: 25px;
  border-radius: 3px 25px;
  background-color: #00000017;
  box-shadow: 0 0 4px 1px #000 inset !important;
  position: relative;
}

@media screen and (max-width: 768px) {
  .profile-user-icon {
    height: 80px;
  }

  .user-email-text,
  .user-phone-text {
    max-width: 55%;
    overflow: hidden;
    display: inline-block;
  }
}
</style>
