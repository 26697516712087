<template>
  <div class="statistics-container" v-if="statisticsForGame">
    <div>
      <div
        v-for="(statistic, index) in statisticsForGame[mode]"
        v-if="statisticsForGame[mode].length"
        :key="'statistic_' + index"
        class="row mx-0 winner py-3 px-0 px-lg-2 text-center"
        :class="[index % 2 ? 'dark-bg' : '']"
      >
        <div class="col-4 px-0" v-if="mode != 'popular'">
          <span class="gray-color font-weight-bold">{{ statistic.user }}</span>
        </div>
        <div class="col-4 px-0 py-4" v-else>
          <span class="gray-color font-weight-bold">{{
            statistic.game_name
          }}</span>
        </div>
        <div v-if="mode != 'popular'" class="col-3 px-0">
          <a
            class="cursor-pointer"
            :title="statistic.game_name"
            @click.prevent.stop="gotoGameView(statistic)"
            v-lazy-container="{
              selector: 'img',
              loading: require('@/../public/images/icons/game-images-loader.svg'),
            }"
          >
            <img
              :src="$config.baseUrl + '/' + statistic.picture"
              alt="MagnetBet онлайн Казино"
              :data-src="$config.baseUrl + '/' + statistic.picture"
              class="winner-game-img mb-2"
            />
            <br />
            <span class="text-white">{{ statistic.game_name }}</span>
          </a>
        </div>
        <div v-else class="col-3 px-0">
          <a
            class="cursor-pointer"
            :title="statistic.game_name"
            @click.prevent.stop="gotoGameView(statistic, mode)"
            v-lazy-container="{
              selector: 'img',
              loading: require('@/../public/images/icons/game-images-loader.svg'),
            }"
          >
            <img
              :src="$config.baseUrl + '/' + statistic.preview"
              alt="MagnetBet онлайн Казино"
              :data-src="$config.baseUrl + '/' + statistic.preview"
              class="winner-game-img mb-2"
            />
            <br />
          </a>
        </div>
        <div v-if="mode != 'popular'" class="col-5 px-0">
          <span class="win-amount"> {{ statistic.amount }} Руб </span>
        </div>
        <div v-else class="col-5 px-0 py-4">
          <span class="win-amount">
            Сыграно {{ statistic.game_count }} раз</span
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="alert alert-primary">
      <div class="col-12 resp-loader loading">
        <i class="fa fa-spinner fa-spin fa-fw" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemStatisticsForGame",
  props: {
    mode: String,
  },
  data() {
    return {
      loader: require("@/../public/images/icons/small-loader.svg"),
    };
  },
  mounted() {
    this.$store.dispatch("fetchStatisticsForGame");
  },
  computed: {
    statisticsForGame() {
      let stat = this.$store.getters["statisticsForGame"];
      if (!stat) {
        this.$store.dispatch("fetchStatisticsForGame");
      }
      return this.$store.getters["statisticsForGame"];
    },
  },
  methods: {
    gotoGameView(data, type) {
      if (!this.$store.getters.userData) {
        this.$root.$emit("bv::show::modal", "loginModal");
        return this.$root.$emit(
          "reasonToLogin",
          "Для запуска игры нужно авторизоваться"
        );
      }
      const gameName = data.game_name.split(" ").join("-");
      if (type != "popular") {
        this.$router.push({
          name: "LaunchGame",
          params: {
            gameName: gameName,
            gameId: data.game_id,
            game: {
              name: gameName,
              gameId: data.game_id,
              provider: data.provider,
              picture: data.picture,
            },
            provider: data.provider,
            isLive: 0,
            restoring: false,
          },
        });
      } else {
        this.$router.push({
          name: "LaunchGame",
          params: {
            gameName: gameName,
            gameId: data.game,
            game: {
              name: gameName,
              gameId: data.game,
              provider: data.type,
              picture: data.preview,
            },
            provider: data.type,
            isLive: 0,
            restoring: false,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.dark-bg {
  background-color: #202121;
}
</style>
