import Config from '@/config'
import io from 'socket.io-client'

const Socket = {
  host: Config.baseUrl,
  path: '/app/socket.io',
  is_connected: false,
  is_registered: false,
  need_to_connect: true,
  socket: null,
  onHeartbeatActions: [],
  onRegisteredActions: [],
  emitedEents: [],
  registredCallBacks: {},
  registreCallBack: function registreCallBack (key, callback) {
    this.registredCallBacks[key] = function (data) {
      callback(data)
    }
  },
  _: function _ (jsonString) {
    try {
      return JSON.parse(jsonString)
    } catch (e) {
      return jsonString
    }
  },
  do_connect: function () {
    if (!this.need_to_connect) {
      return
    }
    this.io = io
    this.socket = this.io(this.host, {
      path: this.path,
      reconnection: false,
      transports: ['websocket']
    })

    this.setEvents()
  },
  setEvents () {
    this.socket.on('connect', (data) => {
      this.connected(data)
    })
    this.socket.on('disconnect', (data) => {
      this.disconnected(data)
    })
    const _this = this
    var events = {
      connect (data) {
        _this.connected(data)
      },
      disconnect (data) {
        _this.disconnected(data)
      },
      reconnect (data) {
        _this.reconnected(data)
      },
      heartbeat (data) {
        _this.heartbeated(data)
      },
      registered (data) {
        _this.registered(data)
      },
      message (data) {
        _this.message(data)
      },
      deauth () {
        _this.deauth()
      },
      refresh_page () {
        _this.refresh_page()
      },
      log_out () {
        _this.deauth()
      }
    }

    this.socket.onevent = (packet) => {
      var args = packet.data || []

      var event = args[0]
      if (typeof events[event] === 'function') {
        events[event](args[1])
      }

      var callback = _this.registredCallBacks[event]
      if (typeof callback === 'function') {
        try {
          callback(JSON.parse(args[1]))
        } catch (e) {
          callback(args[1])
        }
      }
    }
  },
  setEvent (eventName, callback) {
    if (this.socket && this.is_connected) {
      this.socket.on(eventName, (data) => {
        if (typeof callback === 'function') {
          callback(data)
        }
      })
    } else {
      console.log('cannot set events. not connected')
    }
  },
  fireEvent (eventName, sendData) {
    if (!this.is_connected) {
      return 0
    }
    this.socket.emit(eventName, sendData)
  },
  send (jsonObject) {
    let sendData = Object.assign(jsonObject, this.getDefaultData())
    sendData = JSON.stringify(sendData)
    this.fireEvent('message', sendData)
  },
  connected () {
    this.is_connected = true
    this.goto_register()
  },
  disconnected () {
    this.is_connected = false
    this.is_registered = false
    if (this.need_to_connect) {
      this.do_reconnect()
    }
  },
  do_reconnect () {
    this.reconnectIntervalID = setInterval(() => {
      if (this.is_connected) {
        clearInterval(this.reconnectIntervalID)
        return
      }
      this.do_connect()
    }, 2000)
  },
  reconnected () {
    console.log('reconnected!!!')
  },
  refresh_page () {
    window.location.reload()
  },
  heartbeated (heartbeatData) {
    heartbeatData = this._(heartbeatData)
    this.balance = heartbeatData.balance
    if (typeof this.balance !== 'undefined') {
      this.store.dispatch('dispatchUserBalance', this.balance)
    }
  },
  goto_register () {
    this.fireEvent('register', this.defaultData)
  },
  registered: function () {
    this.doOnregisteredActions()
    this.is_registered = true
    this.startHeartbeats()
  },
  doOnregisteredActions () {
    if (this.onRegisteredActions.length > 0) {
      for (const key in this.onRegisteredActions) {
        if (typeof this.onRegisteredActions[key] === 'function') {
          this.onRegisteredActions[key]()
        }
      }
    }
  },
  startHeartbeats () {
    this.heartbeatIntervalId = setInterval(() => {
      if (!this.is_registered || !this.is_connected) {
        clearInterval(this.heartbeatIntervalId)
        return
      }

      this.fireEvent('heartbeat', this.getDefaultData())
    }, 1200)
  },
  startGame (data) {
    this.fireEvent('opengame', JSON.stringify(data))
  },
  closeGame (data) {
    this.fireEvent('closegame', JSON.stringify(data))
  },
  message (data) {
    console.log(data)
    data = this._(data)
  },
  deauth () {
    this.need_to_connect = false
    this.socket.disconnect()
    this.store.dispatch('logoutUser')
    window.location.href = ''
  },
  getDefaultData () {
    let defaultData = {}
    const userInfo = this.store.getters.userData
    if (userInfo) {
      if (!!userInfo.login && !!userInfo.key && !!userInfo.id) {
        defaultData = {
          login: userInfo.login,
          key: userInfo.key,
          id: userInfo.id,
          type: 'client',
          location: window.location.href
        }
      }
    }
    if (!defaultData.type) {
      defaultData = {
        type: 'guest'
      }
    }

    return defaultData
  },
  install (Vue, store) {
    if (this.is_connected) {
      return
    }
    this.store = store
    this.defaultData = this.getDefaultData()
    this.do_connect()
    Vue.prototype.$socketStartGame = (data) => {
      this.startGame(data)
    }
    Vue.prototype.$socketCloseGame = (data) => {
      this.closeGame(data)
    }
  }
}
export default Socket
