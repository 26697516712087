<template>
  <div class="py-3 py-lg-5 col-12" v-if="userData && ticketTypes">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Поддержка клиентов</span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-lg-8 mx-auto px-0">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Поддержка клиентов
      </h4>
      <div class="col-12 mx-auto py-3 py-lg-5 page-main-block px-0 row mx-0 justify-content-between">
        <div class="col-12 col-sm-12 col-md-12 mt-3 mt-lg-3 mx-auto px-0 tickets-tab">
          <b-tabs content-class="mt-0"
              class="tab account-tab ticket-tab px-0"
              nav-class="col-12 mx-auto px-0">
            <b-tab class="account-tab-item ticket-tab-item col-xs-12 px-0 p-1 p-lg-4 pt-0" title="Открыть Заявку" active>
              <open-ticket></open-ticket>
            </b-tab>
            <b-tab class="account-tab-item ticket-tab-item col-12 px-0 p-4 pt-0" title="Открытые Заявки">
              <opened-tickets></opened-tickets>
            </b-tab>
            <b-tab class="account-tab-item ticket-tab-item col-12 px-0 p-4 pt-0" title="Закрытые Заявки">
              <closed-tickets></closed-tickets>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import openTicket from '@/components/main/tickets/OpenTicket'
import openedTickets from '@/components/main/tickets/OpenedTicket'
import closedTickets from '@/components/main/tickets/ClosedTicket'
import store from "@/store";

export default {
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    ticketTypes() {
      return this.$store.getters.ticketTypes
    }
  },
  async mounted() {
    window.scrollTo({
      top: 0
    })
    await store.dispatch('fetchTicketTypes')
    await store.dispatch('fetchTickets')
  },
  components: {
    openTicket,
    openedTickets,
    closedTickets
  },
  watch: {
    userData (newValue) {
      if (newValue) {
        this.$router.push({ name: 'home' })
      }
    }
  },

}
</script>

<style>
@media screen and (max-width: 920px){
  .ticket-tab .nav-tabs .nav-item{
   margin-bottom: 10px;
    width: 100% !important;
  }
}
</style>