import { render, staticRenderFns } from "./GameCategories.vue?vue&type=template&id=4daf5460&scoped=true"
import script from "./GameCategories.vue?vue&type=script&lang=js"
export * from "./GameCategories.vue?vue&type=script&lang=js"
import style0 from "./GameCategories.vue?vue&type=style&index=0&id=4daf5460&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4daf5460",
  null
  
)

export default component.exports