<template>
  <div class="py-3 py-lg-5 col-12">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Статистика Игр </span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-lg-10 col-xl-8 mx-auto px-0">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Статистика Игр
      </h4>

      <div
        class="col-12 mx-auto text-center my-5 py-3 page-main-block px-0 px-lg-3"
      >
        <div class="col-12 col-sm-12 col-md-12 mt-3 mt-lg-3 mx-auto px-0">
          <b-tabs
            content-class="mt-0 mt-lg-3"
            class="tab account-tab px-0"
            nav-class="col-12 col-lg-8 mx-auto px-0"
          >
            <b-tab
              class="tab-item col-12 px-0 py-4"
              title="Популярные игры"
              active
            >
              <ItemStatisticsForGame mode="popular" />
            </b-tab>
            <b-tab class="tab-item" title="Большие выигрыши">
              <ItemStatisticsForGame mode="big_wins" />
            </b-tab>
            <b-tab class="tab-item" title="Вчерашние крупные выигрыши">
              <ItemStatisticsForGame mode="yesterdays_big_wins" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemStatisticsForGame from "@/components/main/ItemStatisticsForGame";

export default {
  components: {
    ItemStatisticsForGame,
  },
  mounted() {
    this.$store.dispatch("fetchStatisticsForGame");
    window.scrollTo({
      top: 0,
    });
  },
};
</script>
