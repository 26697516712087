<template>
  <b-modal
    v-if="!userData"
    id="loginModal"
    ref="loginModal"
    size="md"
    modal-class="main-modal page-main-block"
    ok-title="Закрыть"
    ok-only
    ok-variant="success modal-ok"
    header-class="text-white border-0"
    header-close-variant="orange"
    :title="reason"
    title-class="text-center underlined-muted-title"
    footer-class="border-0"
    hide-footer
    body-class="pt-0"
    centered>
    <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
      <div class="col-12 mx-auto pt-3 pb-2 px-0">
        <b-form
          class="login-form popup-login-form py-t pb-0"
          @submit.prevent="Login">
          <div class="col-12  mx-auto mb-1">
            <div class="col-12 px-0">
              <div class="input-group mb-4">
                <div class="col-12 px-0">
                  <input
                    v-model="payload.login"
                    class="form-control auth-input"
                    required
                    type="text"
                    placeholder="Ваш логин"
                    :class="__validate('login', payload.login)">
                  <i class="fa input-hint"></i>
                </div>
              </div>
            </div>

            <div class="col-12 px-0">
              <div class="input-group mb-4">
                <div class="col-12 px-0">
                  <input
                    v-model="payload.password"
                    type="password"
                    class="form-control auth-input"
                    required
                    placeholder="Ваш пароль"
                    :class="__validate('password', payload.password)">
                  <i class="fa input-hint"></i>
                </div>
              </div>
            </div>

            <div class="col-12 mb-3 px-0">
              <alert :message="response" />
              <div class="clearfix" />
            </div>

            <div class="col-12 px-0">
              <div class="col-12 mx-auto px-0">
                <div class="green-btn-bg">
                  <button
                          name="isSubmitting"
                    class="btn auth-btn btn-green w-100 red-btn" :disabled="isSubmitting"
                    @click.prevent="Login">
                    Войти!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>

        <div class="col-12  mx-auto justify-content-between row px-0 px-lg-3">
          <div class="col-12 mt-3 px-3 px-lg-0">
            <div class="mx-auto col-12 px-0">
              <router-link
                class="text-muted w-50 float-left"
                :to="{ path: '/registration' }">
                <small class="register-now">Регистрация</small>
              </router-link>
              <small class="text-muted cursor-pointer w-50 d-inline-block text-right" @click="openRecoverModal">
                Забыли пароль?
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'LoginPopUp',
  components: {
    Alert
  },
  data () {
    return {
      response: null,
      isSubmitting: false,
      reason: '',
      payload: {
        login: '',
        password: ''
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  methods: {
    openRecoverModal () {
      return this.$root.$emit('bv::show::modal', 'recoverAccountModal')
    },
    Login () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/Login', {
          authorize: this.payload
        }
      ).then((userData) => {
        const data = userData.data
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchUserData')
          this.response = { status: 'ok', msg: 'Добро пожаловать' }
          this.$store.dispatch('fetchMessages')
          this.$store.dispatch('getMainSettings')
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
        }
      })
    }
  },
  mounted () {
    if (this.userData) {
      this.$router.push({ name: 'home' })
    }
    const _this = this
    this.$root.$on('reasonToLogin', function (value) {
      _this.reason = value
    })
  },
  watch: {
    userData (newVal, oldVal) {
      if (newVal && oldVal) {
        if (this.userData) {
          this.$router.push({ path: '/' })
        }
      }
    },
    $route: function () {
      this.response = null
    },
    reasonToLogin: {
      deep: true
    }
  }
}
</script>

<style>
    .underlined-muted-title{
        font-size: 15px;
        color:#aba9a9;
        border-bottom: 1px solid #aba9a9;
        margin: 0 auto;
        text-shadow:0 0 1px #aba9a9;
        user-select: none;
    }

    #loginModal .modal-dialog{
      max-width: 420px;
    }

    #loginModal .modal-header .close{
      margin: 0 !important;
      padding: 0 !important;
      position: absolute;
      right: 15px;
      top: 10px;
    }
    #loginModal .text-muted{
      color:#c0c0c0 !important;
    }
    #loginModal .modal-header .modal-title{
      max-width: 90%;
    }
    .popup-login-form .input-hint{
      right: 5px !important;
    }
    .popup-login-form input:-webkit-autofill{
      padding-left: 0 !important;
      padding-right: 0 !important;
      box-shadow: 0 0 0 30px #3e3e3e inset !important;
    }
    .popup-login-form input:autofill,
    .popup-login-form input:autofill:focus,
    .popup-login-form input:autofill:hover
    {
      box-shadow: 0 0 0 33px #2c2c2c00 inset !important;
    }
</style>
