<template>
  <div class="col-12 register-page my-5">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        <router-link :to="{name : 'home'}" class="text-white">
          Главная
        </router-link> /
        <span class="active-router-text">Регистрация</span>
      </h6>
    </div>
    <div class="col-12 register-block px-0 px-lg-2">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Регистрация
      </h4>
      <div class="col-12 col-sm-12 col-md-10 col-lg-7 mx-auto register-form-area py-5 page-main-block px-0 px-lg-2">
        <b-form
          class="registration-form row mx-0 justify-content-between"
          @submit.prevent="Registration">
          <div class="col-12 col-sm-6 px-0 row mx-0 justify-content-center">
            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.user_login"
                    ref="reg_user_login"
                    id="reg_user_login"
                    autofocus
                    name="user_login"
                    class="form-control auth-input"
                    :class="__validate('login', payload.user_login)"
                    required
                    type="text"
                    placeholder="Логин *">
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.user_email"
                    :class="__validate('email', payload.user_email)"
                    name="user_email"
                    class="form-control auth-input"
                    type="email"
                    placeholder="Емайл *"
                    required>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <the-mask
                    v-model="payload.user_phone"
                    :class="__validate('phone', payload.user_phone)"
                    :mask="getSelectedMethodMask"
                    name="user_phone"
                    class="form-control auth-input"
                    placeholder="Номер телефона"
                    required type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 px-0 row mx-0 justify-content-center">
            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.user_password"
                    :class="__validate('password', payload.user_password)"
                    name="user_password"
                    type="password"
                    ref="reg_user_password"
                    class="form-control auth-input"
                    required
                    placeholder="Пароль *">
                  <i class="fa input-hint"></i>
                  <span id="toggle-password" @click.prevent="togglePassword()">
                    <i class="fa fa-eye"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.user_password_confirm"
                    :class="validatePasswordConfirm()"
                    name="user_password_confirm"
                    type="password"
                    class="form-control auth-input"
                    required
                    placeholder="Пароль еще раз *">
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.promo_code"
                    :class="__validate('promo', payload.promo_code)"
                    name="promocode"
                    type="text"
                    class="form-control auth-input"
                    placeholder="Промокод">
                  <small class="form-text text-muted">Если нет промокода, оставьте поле пустым</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12">
              <small class="form-text text-muted my-2">
                <i class="fa fa-info-circle" />
                Поля помеченные * обязательны к заполнению
              </small>
            </div>
          </div>
          <div class="col-12 agree-terms-area">
            <div class="col-12">
              <small class="form-text text-muted my-2">
                <input
                  v-model="payload.confirm_terms"
                  type="checkbox"
                  class="reg-agree-terms"
                  required="required">
                Я согласен с правилами и условиями и защитой информации
                <button name="submit"
                        v-b-modal.agreeTermsModal
                        type="button"
                        class="bg-transparent border-0 text-muted ">
                  <i class="fa fa-info-circle" />
                </button>
              </small>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 col-lg-8 mx-auto my-3">
              <alert :message="response" />
              <div class="clearfix" />
            </div>

            <div class="col-12 mt-3 mx-0">
              <div class="col-12 col-md-6 col-xl-3 mx-auto px-0">
                <div class="">
                  <button
                    name="submit"
                    class="btn auth-btn red-btn w-100 reg-submit"
                    :disabled="isSubmitting"
                    @click.prevent="Registration">
                    Зарегистрироваться
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12 mt-3">
              <div class="col-12 col-md-6 col-xl-3 mx-auto px-0">
                <router-link class="btn auth-btn gray-btn w-100" :to="{ path: '/signin' }">
                  Авторизация
                </router-link>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <b-modal
      id="agreeTermsModal"
      title="Условия и соглашения"
      size="xl"
      modal-class="main-modal register-terms-modal"
      ok-title="Закрыть"
      ok-only
      ok-variant="success modal-ok"
      header-class="text-white border-0"
      header-close-variant="white"
      footer-class="border-0">
      <Terms></Terms>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import Terms from './RegisterTerms'

export default {
  name: 'Register',
  data () {
    return {
      response: null,
      isSubmitting: false,
      payload: {
        user_login: '',
        user_email: '',
        user_password: '',
        user_phone: '',
        promo_code: '',
        user_currency: 1,
        user_password_confirm: '',
        confirm_terms: false
      }
    }
  },
  components: {
    Alert,
    Terms
  },
  methods: {
    togglePassword() {
      const input = this.$refs.reg_user_password
      if (input.getAttribute('type') === 'password') {
        return input.setAttribute('type', 'text')
      }
      input.setAttribute('type', 'password')
    },
    validatePasswordConfirm () {
      if (this.payload.user_password === '') {
        return this.__validate('password', this.payload.user_password_confirm)
      }
      if (this.payload.user_password_confirm !== this.payload.user_password) {
        if (this.payload.user_password_confirm === '') {
          return ''
        }
        return 'has-error'
      } else if (this.payload.user_password_confirm !== '' && this.payload.user_password_confirm === this.payload.user_password){
        return 'has-success'
      }
    },
    Registration () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      const login = this.payload.user_login
      const email = this.payload.user_email
      const password = this.payload.user_password
      const passwordConfirm = this.payload.user_password_confirm
      const confirmedTerms = this.payload.confirm_terms

      if (login === '' || email === '' || password === '' || passwordConfirm === '') {
        this.isSubmitting = false
        this.response = { status: 'error', msg: 'Заполните все необходимые поля' }
        return false
      }

      if (password !== '' && password !== passwordConfirm) {
        this.isSubmitting = false
        this.response = { status: 'error', msg: 'Пароль и подтверждения пароля не соовподают' }
        return false
      }

      if (!confirmedTerms) {
        this.isSubmitting = false
        this.response = { status: 'error', msg: 'Вы должны согласиться с правилами и условиями' }
        return false
      }

      this.axios.post(
        this.$_config.baseUrl + '/Api/registration',
        this.payload, {
          withCredentials: true
        }
      ).then(({ data }) => {
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchUserData')
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
        }
      })
    }
  },
  computed: {
    isAuthorized () {
      return this.$store.getters.userData
    },
    getSelectedMethodMask () {
      let masks = ["+#(###)###-##-##","+##(###)###-##-##"];
      return typeof masks === 'string' ? masks :masks.join(',').split(',')
    }
  },
  watch: {
    isAuthorized (newValue) {
      if (newValue) {
        this.$router.push({ name: 'home' })
      }
    }
  },
  mounted () {
    this.$root.$emit('bv::hide::modal', 'loginModal')
    if (this.isAuthorized) {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style>
    .fa-spinner{
        color:#860019
    }
    .register-form-area,.page-main-block{
        background-color: #3e3e3e;
    }
    #toggle-password {
      position: absolute;
      color: #8a8989;
      bottom: 3px;
      right: 40px;
      cursor: pointer;
    }
    .input-hint{
      display: none;
    }
    .auth-input.has-success{
      border-bottom: 1px solid #1ba226 !important;
    }
    .auth-input.has-error{
      border-bottom: 1px solid #940721 !important;
    }
    .auth-input + .input-hint{
      position: absolute;
      right: 18px;
      bottom: 10px;
      font-size: 13px;
    }
    .auth-input.has-success + .input-hint::before{
      content: "\f00c";
      display: block;
      color: #1ba226;
    }
    .auth-input.has-error + .input-hint::before{
      content: "\f071";
      display: block;
      color: #940721;
    }
</style>
<style scoped>
    .registration-form .auth-input:-webkit-autofill,
    .registration-form .auth-input:-webkit-autofill:focus,
    .registration-form .auth-input:-webkit-autofill:hover
    {
        line-height: 24px;
        box-shadow: 0 0 0 33px #2c2c2c inset !important;
        color: #ffffff;
        webkit-text-fill-color: #ffffff;
        outline: none !important;
        font-size: 12px;
    }
    .registration-form .auth-input:autofill,
    .registration-form .auth-input:autofill:focus,
    .registration-form .auth-input:autofill:hover
    {
        line-height: 24px;
        box-shadow: 0 0 0 33px #2c2c2c00 inset !important;
        color: #ffffff;
        webkit-text-fill-color: #ffffff;
        outline: none !important;
        font-size: 12px;
    }
    .registration-form {
      background-color: transparent !important;
    }
    .agree-terms-area .form-text{
        display: inline-block;
    }
    .page-title{
        display: none;
    }
</style>
