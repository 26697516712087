<template>
  <div class="deposit-container py-4 col-12 col-lg-11 col-xl-10  mx-auto mb-5">

    <div class="col-12 page-router mt-0 mt-lg-4">
      <h6 class="text-white w-100 text-center">
        Главная /
        <span class="active-router-text">Версия Андроид</span>
      </h6>
    </div>
    <h4 class="w-100 text-center text-white my-3 my-lg-5 page-main-title">
      Версия Андроид
    </h4>

    <div class="col-12 mx-auto deposit-main-area px-0">
      <div class="row mx-0 justify-content-center">

        <a class="btn download-btn px-5 py-4 mt-3" download :href="$config.baseUrl+'/static/public/application/MagnetBetV6.apk'">
          Скачать приложение
        </a>

      </div>

      <b-modal
          id="confirmWithdrawModal"
          v-model="confirmWithdrawModal"
          size="md"
          modal-class="main-modal"
          :hide-header="true"
          footer-border-variant="gray">
        <div class="col-12 px-0 text-center">
          <span class=""> {{ hint }} </span>

          <div class="row mx-0 my-3">
            <Alert :message="popupMessages"></Alert>
          </div>
        </div>
        <template v-slot:modal-footer="{ ok, cancel}" class="border-0">
          <b-button name="makeWithdraw"
                    size="sm" class="btn red-btn text-white"
                    @click.prevent="makeWithdraw()">
            Вывести
          </b-button>
          <b-button name="cancel"
                    size="sm" class="gray-btn"
                    @click="cancel()">
            Отмена
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'Android',
  data() {
    return {
      selectedMethod: {
        name: 'android',
        min_amount: 100,
        max_amount: 15000,
        type: ''
      },
      payload: {
        amount: '',
      },
      formResponse: {},
      popupMessages: {},
      hint: '',
      isSubmitting: false,
      confirmWithdrawModal: false,
    }
  },
  components: {
    Alert
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },

  },
  methods: {
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      if (!this.selectedMethod.name) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста выберите платежную систему'}
        return false
      }

      if (!this.payload.amount || this.payload.amount != parseFloat(this.payload.amount)) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите правильую сумму'}
        return false
      }

      if (this.payload.amount < this.selectedMethod.min_amount) {
        this.formResponse = {
          status: 'error',
          msg: 'Минимальная сумма для выбранного метода  ' + this.selectedMethod.min_amount
        }
        return false
      }

      if (this.payload.amount > this.selectedMethod.max_amount) {
        this.formResponse = {status: 'error', msg: 'Максимальная сумма для снятия ' + this.selectedMethod.max_amount}
        return false
      }


      this.confirmWithdrawModal = true
      this.hint = 'Подтвердите вывод на сумму ' + this.payload.amount + ' руб.'
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = {status: 'loading'}
      this.popupMessages = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/newWithdraw',
          {
            withdraw_amount: this.payload.amount,
            withdraw_requisites: '',
            method: 'android'
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.amount = null
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
          }, 4000)
        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.chosen-method-info {
  font-size: 14px;
}
.mh-500 {
  min-height: 500px;
}

.download-btn {
  background-color: #860019;
  color: #ffffff !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  animation: zoom-in-zoom-out 2s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
