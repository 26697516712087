<template>
  <div class="statistics-container" v-if="mainStatistics">
    <div class="sliding">
      <div v-for="(winner, index) in mainStatistics[mode]" v-if="mainStatistics[mode].length"
          :key="'winner_'+index"
          class="row mx-0 winner py-3 px-0 px-lg-2 text-center"
          :class="[index % 2 ? 'dark-bg' : '']">

        <div class="col-4 px-0">
          <span class="gray-color font-weight-bold">{{ winner.user }}</span>
        </div>
        <div class="col-3 px-0">
          <a class="cursor-pointer" :title="winner.game_name"
             @click.prevent.stop="gotoGameView(winner)"
             v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">

            <img :src="$config.baseUrl+'/'+winner.picture" alt="MagnetBet онлайн Казино"
                 :data-src="$config.baseUrl+'/'+winner.picture" class="winner-game-img mb-2">
            <br>
            <span class="text-white">{{winner.game_name}}</span>
          </a>
        </div>
        <div class="col-5 px-0">
          <span class="win-amount"> {{ winner.amount }} Руб </span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="alert alert-primary">
      <div class="col-12 resp-loader loading">
        <i class="fa fa-spinner fa-spin fa-fw"/>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'StatisticsItem',
  props: {
    mode: String
  },
  data() {
    return {
      loader: require('@/../public/images/icons/small-loader.svg')
    }
  },
  mounted() {
    this.$store.dispatch('fetchMainStatistics')
  },
  computed: {
    mainStatistics() {
      let stat = this.$store.getters['mainStatistics'];
      if (!stat) {
        this.$store.dispatch('fetchMainStatistics')
      }
      return this.$store.getters['mainStatistics']
    }
  },
  methods: {
    gotoGameView(data) {
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для запуска игры нужно авторизоваться')
      }
      const gameName = data.game_name.split(' ').join('-')

      this.$router.push({
        name: 'LaunchGame',
        params: {
          gameName: gameName,
          gameId: data.game_id,
          game: {
            name: gameName,
            gameId: data.game_id,
            provider: data.provider,
            picture: data.picture
          },
          provider: data.provider,
          isLive: 0,
          restoring: false
        }
      })
    }
  }
}
</script>

<style scoped>
.statistics-container {
  overflow: hidden;
  max-height: 800px;
}

.dark-bg {
  background-color: #202121;
}

@-webkit-keyframes sliding /* Safari and Chrome */ {
  from {
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-12700px);
    -o-transform: translateY(-12700px);
    transform: translateY(-12700px);
  }
}
@keyframes sliding {
  from {
    -ms-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -ms-transform: translateY(-12700px);
    -moz-transform: translateY(-12700px);
    -webkit-transform: translateY(-12700px);
    -o-transform: translateY(-12700px);
    transform: translateY(-12700px);
  }
}
.sliding {
  height: 100%;
  -webkit-animation: sliding 100s linear infinite;
  -moz-animation: sliding 100s linear infinite;
  -ms-animation: sliding 100s linear infinite;
  -o-animation: sliding 100s linear infinite;
  animation: sliding 100s linear infinite;
}

@media screen and (max-width: 1024px) {
  .sliding {
    height: 100%;
    -webkit-animation: sliding 100s linear infinite;
    -moz-animation: sliding 100s linear infinite;
    -ms-animation: sliding 100s linear infinite;
    -o-animation: sliding 100s linear infinite;
    animation: sliding 100s linear infinite;
  }


  @-webkit-keyframes sliding /* Safari and Chrome */ {
    from {
      -webkit-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-11400px);
      -o-transform: translateY(-11400px);
      transform: translateY(-11400px);
    }
  }
  @keyframes sliding {
    from {
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -ms-transform: translateY(-11400px);
      -moz-transform: translateY(-11400px);
      -webkit-transform: translateY(-11400px);
      -o-transform: translateY(-11400px);
      transform: translateY(-11400px);
    }
  }
}
</style>