<template>
  <div class="py-3 py-lg-5 col-12">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Бонусы</span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-lg-11 col-xl-10 mx-auto px-0">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Бонусы
      </h4>
      <div class="col-12 row px-0 ">
        <div class="row col-12 col-md-6 mx-0 mt-5 justify-content-center">
          <div class="text-center double-promo-box cash-back-banner w-100">
            <p>
              <img :src="require('@/../public/images/bonuses/cash-back-bonuse.png')" alt="magnet.bet casino"
                @click="imageClick('cashbackBonus')" class="double-promo-banner">
            </p>
          </div>
        </div>
        <div class="col-12 row mx-0 col-md-6 mt-4 mt-md-5 justify-content-center">
          <div class="text-center double-promo-box cash-back-banner w-100 ">
            <p>
              <img :src="require('@/../public/images/bonuses/selfie-bonuse.png')" @click="imageClick('selfieBonus')"
                alt="magnet.bet casino" class="selfie-banner">
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 row px-0 ">
        <div class="row col-12 col-md-6 mx-0 mt-5 justify-content-center">
          <div class="text-center double-promo-box cash-back-banner w-100">
            <p>
              <img :src="require('@/../public/images/bonuses/bonus_email.png')" alt="magnet.bet casino"
                @click="imageClick('emailBonus')" class="double-promo-banner">
            </p>
          </div>
        </div>
        <div class="col-12 row mx-0 col-md-6 mt-4 mt-md-5 justify-content-center">
          <div class="text-center double-promo-box cash-back-banner w-100 ">
            <p>
              <img :src="require('@/../public/images/bonuses/magnet-bonus.png')"
                alt="magnet.bet casino" class="selfie-banner">
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 mt-4" v-if="socialNetworkBonusEnabled">
        <h3 class="text-center mt-3 mb-5 ">Прикрепи соц-сети и получи кэш!</h3>
        <div class="row mt-5 justify-content-center" v-if="userData">
          <div class="clearfix"></div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 social-network-itm text-center"
            v-for="(bonus, idx) in socialNetworkBonusEnabled" :key="idx">
            <img :src="$config.baseUrl + bonus.icon" alt="Magnet.Bet - Bonuses">

            <p class="mt-4">
              Подтверди {{ bonus.title }} и получи {{ bonus.confirm_bonus }} Руб.
            </p>


            <p class="mb-5 mt-4 verify-btn" v-if="idx === 'email'">
              <span class="btn text-white red-btn"
                v-if="userData.email_confirmed == 0 && (userData.sum_replenishment >= 2500 || userData.isPromo)">
                <a @click.prevent="confirmEmail">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span v-else-if="userData.email_confirmed == 0 && userData.sum_replenishment < 2500 && !userData.isPromo"
                class="btn text-white red-btn disable_bonus" @click="disableBonusinfo()">
                <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>

            <p class="mb-5 mt-4 verify-btn" v-else-if="idx === 'phone'">
              <span class="btn text-white red-btn"
                v-if="userData.phone_confirmed == 0 && (userData.sum_replenishment >= 2500 || userData.isPromo)">
                <a @click.prevent="confirmPhone">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span v-else-if="userData.phone_confirmed == 0 && userData.sum_replenishment < 2500 && !userData.isPromo"
                class="btn text-white red-btn disable_bonus" @click="disableBonusinfo()">
                <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>

            <p class="mb-5 mt-4 verify-btn" v-else-if="idx === 'selfie'">
              <span class="btn text-white red-btn"
                v-if="userData.selfieData.status == -1 && userData.verified == 1 && userData.sum_replenishment >= 2500">
                <a @click.prevent="confirmSelfie">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == -1  && userData.sum_replenishment >= 2500">
                <a @click="disableSelfieBonus()">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 0 && userData.sum_replenishment >= 2500">
                В обработке
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 2 && userData.verified == 1 && userData.sum_replenishment >= 2500">
                Отказано 
                <br />
                <a @click.prevent="confirmSelfie">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 2  && userData.sum_replenishment >= 2500">
                Отказано
                <br />
                <a @click="disableSelfieBonus()">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span
                v-else-if="userData.selfieData.status == -1 && userData.sum_replenishment < 2500"
                class="btn text-white red-btn disable_bonus" @click="disableSelfieBonus()">
                <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>

            <p class="mb-5 mt-4 verify-btn" v-else>
              <span
                v-if="userData.socialNetworkConfirmed[idx] == 0 && userData.sum_replenishment >= 2500">
                <a class="btn text-white red-btn" :href="$config.baseUrl + '/Index/AttachSocialNetworkRequest/' + idx"
                  target="_blank">
                  <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
                </a>
              </span>

              <span
                v-else-if="userData.socialNetworkConfirmed[idx] == 0 && userData.sum_replenishment < 2500"
                class="btn text-white red-btn disable_bonus" @click="disableBonusinfo()">
                <i class="fa fa-link"></i> Получить {{ bonus.confirm_bonus }} Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                Подтвержден
              </span>
            </p>
          </div>
        </div>

        <div class="row mt-5 justify-content-center" @click="checkUser()" v-else>
          <div class="clearfix"></div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 social-network-itm text-center"
            v-for="(bonus, idx) in socialNetworkBonusEnabled" :key="idx">
            <img :src="$config.baseUrl + bonus.icon" alt="Magnet.Bet - Bonuses">

            <p class="mt-4">
              Подтверди {{ bonus.title }} и получи {{ bonus.confirm_bonus }} Руб.
            </p>
          </div>
        </div>
        <ConfirmSelfie :user-data="userData"></ConfirmSelfie>
        <ConfirmEmail :user-data="userData"></ConfirmEmail>
        <ConfirmPhoneViaCall :user-data="userData" v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'">
        </ConfirmPhoneViaCall>
        <ConfirmPhone :user-data="userData" v-else></ConfirmPhone>
      </div>
    </div>

    <b-modal id="bonuse-disable-info" ref="bonuse-disable-info" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer
      body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto">
        <div class="col-12 mx-auto py-3 px-0">
          <div class="col-12 px-0">
            <div class="col-12 mx-auto pt-3 pb-2 px-0">
              <p class="text-left" v-if="selfieBonusDisableInfo">
                Фото подтверждение
                <br /><br />
                Для игроков казино Magnet.Bet
                <br /><br />
                Бонус доступен тем игрокам, которые пополнили свой счет на общую
                сумму не менее 2500 руб. с момента регистрации.
                <br /><br />
                Для подробной информации обратитесь к менеджеру сайта
                <span v-if="getTgManagerInfo('nickname')"> через  <a :href="getTgManagerInfo()" target="_blank">{{getTgManagerInfo('nickname')}}</a></span>.
              </p>
              <p v-else-if="emailBonusVerified">
                Напишите свой логин на почту <a v-if="getEmailInfo()" :href="getEmailInfo('mailto')"> {{ getEmailInfo() }}</a> для получения 100₽, после ручной проверки менеджер выдаст бонус в течении 24 часов.
              </p>
              <p v-else-if="emailBonusNotVerified">
                Для использования бонуса необходима полная верификация (обычная и селфи)
              </p>
              <p v-else-if="cashbackText">Каждый месяц 1-ого и 15-ого числа вам будет начислен возврат денег в случае
                проигрыша. Данный бонус начисляется автоматическим образом.</p>
              <p class="text-center" v-else>Бонус будет доступен если у Вас пополнение от 2500 рублей и выше</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import ConfirmEmail from "@/components/user/actions/ConfirmEmail";
import ConfirmPhoneViaCall from '@/components/user/actions/ConfirmPhoneViaCall'
import ConfirmPhone from '@/components/user/actions/ConfirmPhone'
import ConfirmSelfie from "../user/actions/ConfirmSelfie.vue";

export default {
  data() {
    return {
      cashbackText: false,
      loader: require('@/../public/images/icons/small-loader.svg'),
      selfieBonusDisableInfo: false,
      emailBonusNotVerified:false,
      emailBonusVerified:false,
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    socialNetworkBonusEnabled() {
      return this.$store.getters['getMainSettings'].socialNetworkBonusEnabled
    },
    mainSettings() {
      return this.$store.getters.getMainSettings
    },
  },
  methods: {
    getTgManagerInfo(type = 'url') {
      const tgManager = this.mainSettings.socialLinks.tg_manager;
      if (!tgManager) {
        return '';
      }

      if (type === 'url') {
        return tgManager;
      }

      const urlObject = new URL(tgManager);
      return `@${urlObject.pathname.split('/').pop()}`;
    },
    getEmailInfo(type = '') {
      const email = this.mainSettings.socialLinks.email;
      if (!email) {
        return '';
      }
      return type === 'mailto' ? `mailto:${email}` : email;
    },
    imageClick(val) {
      if (val == 'cashbackBonus') {
        this.cashbackText = true
        this.selfieBonusDisableInfo = false,
        this.emailBonusNotVerified = false,
        this.emailBonusVerified = false,
        this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
      }
      else if(val == 'emailBonus'){
        if(this.userData.verified == 1 && this.userData.selfieData.status == 1){
          this.emailBonusVerified = true,
          this.cashbackText = false,
          this.selfieBonusDisableInfo = false,
          this.emailBonusNotVerified = false,
          this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
        }else{
          this.emailBonusNotVerified = true,
          this.emailBonusVerified = false,
          this.cashbackText = false,
          this.selfieBonusDisableInfo = false,
          this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
        }
      }
      else {
        if ((this.userData.selfieData.status == -1 || this.userData.selfieData.status == 2) && (this.userData.sum_replenishment >= 2500)) {
          this.$root.$emit('bv::show::modal', 'confirmSelfieModal')
        }
        else if (this.userData.sum_replenishment < 2500 || !this.userData.isPromo) {
          this.cashbackText = false
          this.selfieBonusDisableInfo = true,
          this.emailBonusNotVerified = false,
          this.emailBonusVerified = false,
          this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
        }
      }
    },
    getIcon(icon) {
      return require('@/../public/images/icons/bonuses/' + icon)
    },
    confirmPhone() {
      this.$root.$emit('bv::show::modal', 'confirmPhoneModal')
    },
    confirmEmail() {
      this.$root.$emit('bv::show::modal', 'confirmEmailModal')
    },
    confirmSelfie() {
      console.log(this.userData);
      this.$root.$emit('bv::show::modal', 'confirmSelfieModal')
    },
    disableBonusinfo() {
      this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
      this.selfieBonusDisableInfo = false
      this.cashbackText = false
    },
    disableSelfieBonus() {
      this.cashbackText = false
      this.selfieBonusDisableInfo = true
      this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
    },
    checkUser() {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Действия доступно авторизованным клиентам')
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Действия доступно авторизованным клиентам')
      }
    }, 1000)
    window.scrollTo({
      top: 0
    })
  },
  components: {
    ConfirmEmail,
    ConfirmPhone,
    ConfirmPhoneViaCall,
    ConfirmSelfie
  }

}
</script>

<style scoped>
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}


.cash-back-banner,
.selfie-banner {
  cursor: pointer;
}

.download-btn {
  max-width: 250px;
  font-size: 14px;
  background-color: #860019;
  color: #ffffff !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  animation: zoom-in-zoom-out 3s ease infinite;
}


.double-promo-banner,
.selfie-banner {
  width: 100%;
}



@media (min-width: 320px) and (max-width: 1366px) {
  h4 {
    font-size: 16px;
  }
}
@media (min-width: 320px) and (max-width: 765px) {
  .w-50{
  width: 100%!important;
  }
}
.social-network-itm img {
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
}

.social-network-itm:hover img {
  box-shadow: 0 0 17px 0 #ffffffbf;
  transform: scale(1.09)
}

.disable_bonus {
  background-color: rgb(112 83 88 / 50%);
  border: 1px solid #81031a;
}
</style>
