<template>
  <div class="game-category row mx-0">
    <div class="game-category-content my-auto w-100">
      <p class="w-100 text-left text-lg-center m-0 px-2 px-lg-1 category-title">
        <span>
          <svg
            v-if="icon.type === 'svg'" class="game-category-icon"
            v-html="icon.img"/>
          <img
            v-else :src="icon.img"
            alt="MagnetBet онлайн Казино" class="game-category-icon">
        </span>
        <span class="game-category-name">{{ tabName }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameCategories',
  props: ['tabName', 'icon'],
  computed: {
    isMobile () {
      return this.$_config.environment.mobile
    }
  }
}
</script>

<style>
.game-category {
  height: 80px;
  color: #989898;
  cursor: pointer;
}

.game-category-icon {
  width: 30px;
  display: block;
  margin: auto;
  height: 30px;
}

.game-category-name {
  word-wrap: break-word;
}

.category-title {
  overflow: hidden;
}

.game-category-icon:hover path,
.game-category-icon:hover g,
.game-category-icon:hover circle,
.category-title:hover .game-category-icon path,
.category-title:hover .game-category-icon g,
.category-title:hover .game-category-icon circle,
.games-category.active .game-category-icon path,
.games-category.active .game-category-icon g,
.games-category.active .game-category-icon circle {
  fill: #bd1414;
  stroke: #bd1414;
}

    .game-category-icon:hover path.no-stroke,
    .game-category-icon:hover g.no-stroke,
    .game-category-icon:hover circle.no-stroke,
    .category-title:hover .game-category-icon path.no-stroke,
    .category-title:hover .game-category-icon g.no-stroke,
    .category-title:hover .game-category-icon circle.no-stroke,
    .games-category.active .game-category-icon path.no-stroke,
    .games-category.active .game-category-icon g.no-stroke,
    .games-category.active .game-category-icon circle.no-stroke
    {
        stroke: none !important;
    }

    .category-title:hover .game-category-name,
    .games-category.active .game-category-name{
        color:#bd1414;
    }
</style>
<style scoped>

</style>
