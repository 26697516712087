<template>
  <div class="row mx-0 w-100">
    <b-form
        class="create-ticket-form py-4 w-100"
        @submit.prevent="createTicket"
        enctype="multipart/form-data"
    >
      <div class="col-12 mb-3 px-0">
        <div class="col-12">
          <div class="input-group mb-4">
            <div class="col-12" v-if="ticketTypes">
              <select class="form-control auth-input" v-model="payload.type">
                <option value="0" selected>Выберите категорию</option>
                <option v-for="(ticketType, key) in ticketTypes" :key="key" :value="ticketType.id">
                  {{ ticketType.name }}
                </option>
              </select>

            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="input-group mb-4">
            <div class="col-12">
              <input
                  type="text"
                  v-model="payload.subject"
                  class="form-control auth-input comment-area"
                  required
                  placeholder="Заголовок *">
              <i class="fa input-hint"></i>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="input-group mb-4">
            <div class="col-12">
              <textarea
                  v-model="payload.message"
                  class="form-control auth-input comment-area"
                  required
                  placeholder="Сообщение *">
              </textarea>
              <i class="fa input-hint"></i>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="input-group mb-4">
            <div class="col-12">
              <b-form-file
                  v-model="payload.ticketFiles"
                  type="file"
                  class="form-control auth-input comment-area"
                  >
              </b-form-file>
              <i class="fa input-hint"></i>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-3 col-lg-2 px-0">
              <captcha ref="open_ticket_captcha" :captcha_key="'open_ticket_captcha'" />
            </div>
            <div class="col-6 col-sm-9 col-lg-10 px-0">
              <input
                  v-model="payload.captcha"
                  class="form-control auth-input"
                  required
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке *
              </small>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <alert :message="response" class="px-3"/>
          <div class="clearfix"/>
        </div>

        <div class="col-12">
          <div class="col-12 col-lg-9 mx-auto">
            <div class="green-btn-bg">
              <button
                  name="submit"
                  class="btn auth-btn btn-green w-100 red-btn" :disabled="isSubmitting"
                  @click.prevent="createTicket">
                Отправить
              </button>
            </div>
          </div>
        </div>

      </div>
    </b-form>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import Captcha from "@/components/main/Captcha";

export default {
  name: 'OpenTicket',
  components: {
    Captcha,
    Alert
  },
  data() {
    return {
      allowedTypes:[
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/heif',
        'image/heic',
      ],
      response: null,
      isSubmitting: false,
      payload: {
        type: 0,
        message: '',
        captcha: '',
        subject: '',
        ticketFiles: null,
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    ticketTypes() {
      return this.$store.getters.ticketTypes
    }
  },
  methods: {
    createTicket() {
      let formData = new FormData();
      if(this.payload.ticketFiles != null) {
        formData.append('ticketFiles', this.payload.ticketFiles);
      }
      formData.append('type', this.payload.type);
      formData.append('message', this.payload.message);
      formData.append('captcha', this.payload.captcha);
      formData.append('subject', this.payload.subject);

      this.response = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/openTicket/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then((ticketData) => {
        const data = ticketData.data
        this.isSubmitting = false
        this.payload.captcha = ''
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchTickets')
          this.response = {status: 'ok', msg: 'Заявка успешна созданна'}
          this.payload.message = ''
          this.payload.type = 0
          this.payload.subject = ''
          this.payload.ticketFiles = null
        }
        this.$refs.open_ticket_captcha.updateCaptcha()

      }).catch((err) => {
        if (err) {
          this.isSubmitting = false

          this.response = {status: 'error', msg: err.response.data.errors}

        }
        this.payload.captcha = ''
        this.$refs.open_ticket_captcha.updateCaptcha()
      })
    }
  },
  watch: {
    ticketTypes (newValue) {
      this.ticketTypes = newValue
    }
  },
}
</script>

<style scoped>
.comment-area:focus {
  box-shadow: none;
}
</style>