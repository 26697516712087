<template>
  <div class="row recover-by-email-container mx-0">
    <div class="col-12 text-center mx-auto my-2">
      <small class="underlined-muted-title"> На вашу почту прйдет код для смены пароля </small>
    </div>
    <b-row v-if="!isEmailCodeSent" class="w-100 mt-2 mx-auto">
      <b-form
        class="col-12 mb-4 mx-auto recover-form px-0"
        @submit.prevent="sendConfirmationEmail"
      >
        <b-form-group class="mb-4">
          <div class="col-12">
            <input
              v-model="confirmEmailPayload.email"
              type="email"
              placeholder="Введите адрес эл.почты"
              class="main-modal-input form-control"
              required
            >
          </div>
        </b-form-group>

        <b-form-group>
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 px-0">
              <captcha
                ref="email_confirmation_captcha" class="ml-0"
                :captcha_key="'email_confirmation'"
              />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                v-model="confirmEmailPayload.captcha"
                required
                class="form-control main-modal-input"
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>

        <b-row class="w-100 mt-3 mx-auto col-12">
          <alert :message="response" />
        </b-row>

        <b-row v-if="!isEmailCodeSent" class="col-12 mx-0">
          <div class="mx-auto">
            <button
                    name="submit"
              class="btn red-btn text-white px-4"
              :disabled="isSubmitting"
            >
              Отправить письмо
            </button>
          </div>
        </b-row>
      </b-form>
    </b-row>
    <b-row v-else class="w-100 mt-2 mx-auto">
      <b-form
        class="col-12 mb-4 mx-auto recover-form px-0"
        @submit.prevent="sendConfirmationEmail"
      >
        <div class="col-12 px-0">
          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 col-sm-4 d-inline-block text-center">
              <label class="col-form-label white-color">Код из смс</label>
            </div>
            <div class="col-12 col-sm-8 d-inline-block">
              <input
                v-model="confirmEmailPayload.email_code"
                type="text"
                name="email_code"
                class="form-control main-modal-input"
                required
              >
            </div>
          </b-form-group>

          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 col-sm-4 d-inline-block text-center">
              <label class="col-form-label white-color">Пароль</label>
            </div>
            <div class="col-12 col-sm-8 d-inline-block">
              <input
                v-model="confirmEmailPayload.new_password"
                required
                type="password"
                class="form-control main-modal-input"
              >
            </div>
          </b-form-group>

          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 col-sm-4 d-inline-block text-center">
              <label class="col-form-label white-color">Пароль еще раз</label>
            </div>
            <div class="col-12 col-sm-8 d-inline-block">
              <input
                v-model="confirmEmailPayload.new_password_confirm"
                type="password"
                required
                class="form-control main-modal-input"
              >
            </div>
          </b-form-group>
        </div>
        <b-form-group>
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 px-0">
              <captcha
                ref="email_confirmation_captcha" class="ml-0"
                :captcha_key="'email_confirmation'"
              />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                v-model="confirmEmailPayload.captcha"
                required
                class="form-control main-modal-input"
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>

        <b-row class="w-100 mt-3 mx-auto col-12">
          <alert :message="response" />
        </b-row>

        <b-row class="col-12 mx-0">
          <div class="mx-auto">
            <button
                    name="submit"
              class="btn red-btn text-white px-4"
              :disabled="isSubmitting"
            >
              Подтвердить
            </button>
          </div>
        </b-row>
      </b-form>
    </b-row>
  </div>
</template>

<script>
import Captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'

export default {
  name: 'ChangeViaEmail',
  data () {
    return {
      response: null,
      isEmailCodeSent: false,
      isSubmitting: false,
      confirmEmailPayload: {
        email: '',
        captcha: '',
        email_code: '',
        new_password: '',
        new_password_confirm: ''
      }
    }
  },
  components: {
    Captcha,
    Alert
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  methods: {
    sendConfirmationEmail () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      const queryString = `email=${this.confirmEmailPayload.email}&email_confirmation_captcha=${this.confirmEmailPayload.captcha}`

      this.axios.post(
        this.$_config.baseUrl + '/Api/EmailConfirmRequest/', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.isSubmitting = false
        this.response = data
        this.isEmailCodeSent = true
        if (data.status === 'ok') {
          this.confirmEmailPayload.password_restore_captcha = ''
        }
        this.$refs.email_confirmation_captcha.updateCaptcha()
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
        }
      })
    },
    changePasswordWithEmail () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      const sendPayload = {
        new_password: this.confirmEmailPayload.new_password,
        new_password_confirm: this.confirmEmailPayload.new_password_confirm,
        restore_code: this.confirmEmailPayload.email_code,
        password_change_captcha: this.confirmEmailPayload.captcha
      }
      const queryString = this.$_config.makeQueryStringFromObject(sendPayload)

      this.axios.post(
        `${this.$_config.passwordChangeRequests.mail}`,
        queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.response = data
        this.isSubmitting = false
        this.confirmEmailPayload.captcha = ''
      }).catch(err => {
        if (err) {
          this.response = {
            status: 'error',
            msg: 'Ошибка при отправке запроса, попробуйте снова.'
          }
        }
        this.isSubmitting = false
        this.confirmEmailPayload.captcha = ''
      })
      this.$refs.email_confirmation_captcha.updateCaptcha()
    }
  }
}
</script>
